import React from 'react';
import CtaLink from '../atoms/CtaLink';
import PlatformFeatures from '../molecules/PlatformFeatures';

interface HomepagePlatformSectionProps {
  lead?: string;
  title?: string;
  body?: string;
  cta?: {
    to: string;
    label: string;
  };
  features: {
    lead?: string;
    title: string;
    description: string;
    image?: {
      sourceUrl: string;
      altText: string;
    };
    cta?: {
      to: string;
      label: string;
    };
  }[];
}

const HomepagePlatformSection: React.FC<HomepagePlatformSectionProps> = ({
  lead,
  title,
  body,
  cta,
  features,
}) => (
  <section className="py-16 md:py-32 bg-gray-50">
    <div className="u-container">
      <div className="max-w-2xl mb-12 md:mb-16">
        <div className="u-s1 ">
          {lead}
        </div>
        <h2 className="u-h2">
          {title}
        </h2>
        <p className="mb-4 md:mb-6 u-p1 ">{body}</p>
        <CtaLink {...cta} />
      </div>
      <PlatformFeatures features={features} background="linear-gradient(159.3deg, #F7E69A -48.7%, #EE7272 80.57%)" />
    </div>
  </section>
);

export default HomepagePlatformSection;
