import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { ICTA } from '../../interfaces/cta';
import Image from '../base/Image';
import HomepageHeroAnimation from '../organisms/HomepageHeroAnimation';
import 'react-modal-video/css/modal-video.min.css';
interface HomepageHeroProps {
  title: string;
  body: string;
  primaryCta: ICTA;
  secondaryCta: ICTA;
}

const HomepageHero: React.FC<HomepageHeroProps> = ({
  title,
  body,
  primaryCta,
  secondaryCta,
}) => {
  const [isOpen, setOpen] = useState(false);
  const {
    heroBackground,
    heroBackgroundMobile,
    heroImage,
    heroImageMobile,
  } = useStaticQuery(
    graphql`
      query {
        heroBackground: file(name: { eq: "homepage-bg" }) {
          ...FileImageBase64
        }
        heroBackgroundMobile: file(name: { eq: "homepage-hero-mobile-bg" }) {
          ...FileImageBase64
        }
        heroImage: file(name: { eq: "homepage-hero" }) {
          ...FileImage
        }
        heroImageMobile: file(name: { eq: "homepage-hero-mobile" }) {
          ...FileImage
        }
      }
    `,
  );

  return (
    <section className="relative">
      <div className="absolute inset-0 z-0">
        <Image
          data={{ localFile: heroBackgroundMobile }}
          className="hidden object-cover w-full h-full md:block"
          objectFit="cover"
          loading="eager"
        />
        <Image
          data={{ localFile: heroBackground }}
          className="object-cover w-full h-full md:hidden"
          objectFit="cover"
          loading="eager"
        />
      </div>
      <div className="relative">
        <div className="flex flex-col items-center justify-end text-white md:items-center md:flex-row u-container">
          <div className="w-full pt-16 pb-8 text-center md:py-8 lg:py-0 md:w-6/12 md:pr-8 lg:pr-8 md:text-left">
            <h1 className="mb-3 text-4.5xl md:text-4xl font-semibold leading-none md:mb-5 lg:text-5xl xl:text-6xl tracking-pct">
              {title}
            </h1>
            <p className="max-w-lg mx-auto mb-6 text-base leading-snug lg:text-lg md:leading-relaxed xl:text-xl lg:mb-9 lg:mx-0">
              {body}
            </p>
            <div className="items-center space-y-4 md:flex md:space-x-4 md:space-y-0">
              <div
                onClick={() => setOpen(true)}
                className="w-full text-center rounded cursor-pointer select-none md:w-auto u-btn u-btn--primary-white"
              >
                {primaryCta.label}
              </div>

              <ModalVideo
                channel="youtube"
                autoplay={'1'}
                playsinline={true}
                isOpen={isOpen}
                videoId="7mW1QypJvsE"
                onClose={() => setOpen(false)}
              />

              <Link
                to={secondaryCta.to}
                className="w-full text-center md:w-auto u-btn u-btn--outline"
              >
                {secondaryCta.label}
              </Link>
            </div>
          </div>

          <div className="w-full md:w-6/12">
            <HomepageHeroAnimation />
            {/* <Image
              data={{ localFile: heroImage }}
              className="hidden w-full h-auto lg:block"
            />
            <Image
              data={{ localFile: heroImageMobile }}
              className="w-full h-auto lg:hidden"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageHero;
