import React from 'react';

interface TrustedPartnersSectionProps {
  title: string;
  logos: {
    image: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}
const TrustedPartnersSection: React.FC<TrustedPartnersSectionProps> = ({
  title,
  logos,
}) => (
  <section className="py-12 bg-tertiary md:py-14">
    <div className="u-container">
      <div className="flex flex-col items-center justify-center space-y-6 overflow-hidden lg:space-y-0 lg:space-x-24 lg:flex-row">
        <h2 className="u-h6">{title}</h2>
        <div className="grid grid-cols-3 gap-8 ">
          {logos.map(({ image }, index) => (
            <div className="flex justify-center" key={index}>
              <img
                src={image?.sourceUrl}
                alt={image?.altText}
                className="object-contain w-auto h-16"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default TrustedPartnersSection;
