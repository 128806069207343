import React from 'react';
import { Link } from 'gatsby';
import ChevronRight from '../atoms/ChevronRight';
import { ICTA } from '../../interfaces/cta';

interface HeaderMessageProps {
   content: string;
   link: ICTA;
}

const HeaderMessage: React.FC<HeaderMessageProps> = ({
  content,
  link,
}) => {
  return(
    <section className="sticky top-16 z-20 header-message genius-blue-bg py-1.5 px-2 sm:py-3 flex font-semibold">
      <Link to={link.to} className="text-white text-center flex flex-col sm:flex-row justify-center items-center mx-auto svg-hover">
        { content }
        <span className="underline flex items-center mx-auto sm:ml-1">{ link.label }
          <span className="relative flex-shrink-0 w-4 h-2 ml-2 sm:ml-3">
          <ChevronRight/>
          </span>
        </span>
      </Link>
    </section>
  )
};

export default HeaderMessage;
