import React from 'react';
import { IImage } from '../../interfaces/image';

interface WhySpirableSectionProps {
  lead: string;
  title: string;
  body: string;
  image: IImage;
  reasons: { reason: string }[];
}

const WhySpirableSection: React.FC<WhySpirableSectionProps> = ({
  lead,
  title,
  body,
  image,
  reasons,
}) => (
  <section className="pt-16 md:py-32">
    <div className="flex flex-col-reverse md:items-center md:space-x-12 md:flex-row lg:space-x-24 u-container-max">
      <div className="w-full md:w-6/12 ">
        <img
          src={image?.sourceUrl}
          alt={image?.altText}
          className="w-full h-auto"
        />
      </div>
      <div className="w-full px-5 lg:pt-10 md:w-6/12 lg:pr-16 mb-13 lg:mb-0 md:px-0">
        <div className="u-s1">
          {lead}
        </div>
        <h2 className="u-h2">
          {title}
        </h2>
        <p className="mb-8 md:mb-10 u-p1 ">{body}</p>
        <ul className="space-y-4 md:pr-20">
          {reasons.map(({ reason }, index) => (
            <li key={index} className="flex items-start">
              <img
                src="/images/check-badge.png"
                alt=""
                className="flex-shrink-0 h-auto mr-4 w-7 md:mr-6 md:mt-1"
              />
              <div className="text-base font-semibold leading-snug md:text-lg">
                {reason}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default WhySpirableSection;
