import React from 'react';
import LottieAnimation from '../molecules/LottieAnimation';
import HomepageAnimation from '../../assets/animations/homepage-masthead.json';
import RibbonTop from '../../images/ribbon-top.svg';
import RibbonBottom from '../../images/ribbon-bottom.svg';

const HomepageHeroAnimation: React.FC<{}> = () => {
  return React.useMemo(()=>(
      <section className="relative -mb-1">
        <div className="relative w-full" style={{paddingBottom:'134.39%'}}>
          <div className="absolute inset-0 w-full h-full">
            <div className="relative z-10 h-full">
              <div className="absolute top-0 left-0 w-full">
                <img src={RibbonTop} style={{maxWidth:'62%'}} />
              </div>
              <div className="absolute bottom-0 right-0 z-10 w-full">
                <img src={RibbonBottom} className="ml-auto mr-0" style={{ maxWidth:'74%'}} />
              </div>
              <LottieAnimation data={HomepageAnimation} />
            </div>
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <div className="homepage-hero-video-container" style={{maxWidth:'70%',marginLeft:'1.7%',marginBottom:'12%'}}>
                <video className="w-full h-full" poster="/images/homepage-hero-video-poster.jpg" src="/images/homepage-hero-video.mp4" loop={true} autoPlay={true} playsInline={true} muted={true} />
              </div>
            </div>
          </div>
        </div>
      </section>
  ), [])
};

export default HomepageHeroAnimation;
