import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import CtaLink from '../atoms/CtaLink';
import Image from '../base/Image';

interface PlatformFeaturesProps {
  background?: string;
  features: {
    lead?: string;
    title: string;
    description?: string;
    body?: string;
    cta?: {
      to: string;
      label: string;
    };
    image?: {
      sourceUrl: string;
      altText: string;
    };
    transparentImage?: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const PlatformFeatures: React.FC<PlatformFeaturesProps> = ({ features, background }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((index) => (index + 1) % features.length);
    }, 5000);

    setActiveIndex(0);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="mb-8 md:mb-14" style={{background}}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={activeIndex}
          variants={{ out: { opacity: 0 }, in: { opacity: 1 } }}
          initial="out"
          animate="in"
          exit="out"
          className="homepage-features-image "
        >
          <Image
            data={features[activeIndex]?.transparentImage || features[activeIndex]?.image}
            className="hidden w-auto max-h-full lg:block"
            objectFit="contain"
          />
        </motion.div>
      </AnimatePresence>
      </div>
      <div className="flex flex-wrap items-start justify-between space-y-10 lg:space-y-0 lg:space-x-16">
        {features.map(
          ({ lead, title, description, body, cta, image }, index) => (
            <div
              key={index}
              className={` md:mb-16 lg:mb-0 w-full md:w-6/12 lg:w-auto lg:flex-1 p-0 text-left focus:ring-0 transition focus:outline-none md:pr-8 lg:pr-0`}
              onClick={() => setActiveIndex(index)}
            >
              {image && (
                <Image data={image} className="w-full h-auto mb-8 lg:hidden" />
              )}

              <div className="hidden h-1 mb-8 lg:block bg-keyline">
                <AnimatePresence>
                  {activeIndex === index && (
                    <motion.div
                      variants={{
                        initial: {
                          width: 0,
                          opacity: 1,
                        },
                        in: {
                          width: '100%',
                          opacity: 1,
                          transition: {
                            type: 'tween',
                            ease: 'linear',
                            duration: 5,
                          },
                        },
                        out: {
                          width: '100%',
                          opacity: 0,
                          transition: {
                            type: 'tween',
                            ease: 'linear',
                            duration: 0.3,
                          },
                        },
                      }}
                      className="h-1 bg-primary-red"
                      initial="initial"
                      exit="out"
                      animate="in"
                      transition={{
                        type: 'tween',
                        ease: 'linear',
                        duration: 5,
                      }}
                    />
                  )}
                </AnimatePresence>
              </div>
              <div
                className={`${
                  index !== activeIndex && 'lg:opacity-30'
                } cursor-pointer hover:opacity-100 transition-opacity duration-200 ease-in-out`}
              >
                {lead && <div className="u-lead">{lead}</div>}
                <h3 className="mb-3 u-h5">{title}</h3>
                <p className="text-mid-grey">{description || body}</p>
                {cta && (
                  <div className="mt-4 md:mt-7">
                    <CtaLink {...cta} />
                  </div>
                )}
              </div>
            </div>
          ),
        )}
      </div>
    </>
  );
};

export default PlatformFeatures;
