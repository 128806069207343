import { graphql } from 'gatsby';
import moment from 'moment';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BrandsSection from '../components/organisms/BrandsSection';
import FeaturedResources from '../components/organisms/FeaturedResources';
import HomepageBenefitsSection from '../components/organisms/HomepageBenefitsSection';
import HomepageHero from '../components/organisms/HomepageHero';
import HomepagePlatformSection from '../components/organisms/HomepagePlatformSection';
import TrustedPartnersSection from '../components/organisms/TrustedPartnersSection';
import WhySpirableSection from '../components/organisms/WhySpirableSection';
import HeaderMessage from '../components/organisms/HeaderMessage';

const HomePage = ({
  data: { page, allWpPost, allWpGuide, allWpEvent, allWpNewsItem },
}) => {
  const latestPosts = [
    ...allWpPost.edges.map(({ node }) => node),
    ...allWpGuide.edges.map(({ node }) => node),
    ...allWpEvent.edges.map(({ node }) => node),
    ...allWpNewsItem.edges.map(({ node }) => node),
  ].sort((a, b) => (moment(a.dateGmt) < moment(b.dateGmt) ? 1 : -1));

  return (
    <Layout headerBar>
      <Seo post={page} />
      {page.homepage.headerMessage.content &&
      <HeaderMessage {...page.homepage.headerMessage}/>
      }
      <HomepageHero {...page.homepage.homepageHero} />
      <BrandsSection {...page.homepage.brandsSection} />
      <HomepageBenefitsSection {...page.homepage.benefitsSection} background="linear-gradient(158.05deg, rgba(255, 122, 60, 0.5) 14.89%, rgba(227, 25, 55, 0.5) 78.84%)"/>
      <HomepagePlatformSection {...page.homepage.platformSection} />
      <WhySpirableSection {...page.homepage.whySpirableSection} />
      <TrustedPartnersSection {...page.homepage.trustedPartnersSection} />
      <FeaturedResources
        {...page.homepage.featuredResources}
        resources={latestPosts}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query HomepageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      slug
      title
      ...SEO
      homepage {
        headerMessage{
          content
          link{
						label
            to
          }
        }
        homepageHero {
          title
          body
          primaryCta {
            to
            label
          }
          secondaryCta {
            to
            label
          }
        }
        brandsSection {
          title
          logos {
            image {
              ...Image
            }
          }
          cta {
            label
            to
          }
        }
        benefitsSection {
          lead
          title
          body
          benefits {
            title
            description
            cta {
              label
              to
            }
            image {
              ...Image
            }
            transparentImage {
              ...Image
            }
          }
          testimonial {
            logo {
              ...Image
            }
            quote
            author {
              name
              title
              photo {
                ...Image
              }
            }
          }
        }
        platformSection {
          title
          lead
          body
          cta {
            label
            to
          }
          features {
            title
            description
            cta {
              to
              label
            }
            image {
              ...Image
            }
            transparentImage {
              ...Image
            }
          }
        }
        whySpirableSection {
          lead
          title
          body
          image {
            ...Image
          }
          reasons {
            reason
          }
        }
        trustedPartnersSection {
          title
          logos {
            image {
              ...Image
            }
          }
        }
        featuredResources {
          lead
          title
          body
          cta {
            label
            to
          }
        }
      }
    }
    allWpPost(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          date
          title
          nodeType
          slug
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
    allWpEvent(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
          event {
            eventDate
          }
        }
      }
    }
    allWpGuide(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
    allWpNewsItem(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
