import React from 'react';

const ChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (


  <svg className="w-full h-full"  viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.43995 4.09931C9.13328 4.4996 9.13328 5.50034 8.43995 5.90063L1.80998 9.72844C1.11665 10.1287 0.249986 9.62837 0.249986 8.82778L0.249987 1.17216C0.249987 0.371567 1.11665 -0.128802 1.80998 0.271492L8.43995 4.09931Z" fill="white"/>
  </svg>
);

export default ChevronRight;
