import { Link } from 'gatsby';
import React from 'react';
import ArrowRight from '../atoms/ArrowRight';
import FadingLogoStrip from '../molecules/FadingLogoStrip';

interface BrandsSectionProps {
  title: string;
  logos: { image: { sourceUrl: string; altText: string } }[];
  cta: {
    label: string;
    to: string;
  };
}

const BrandsSection: React.FC<BrandsSectionProps> = ({ title, logos, cta }) => (
  <section className="flex flex-col items-center py-12 border-b lg:py-20 border-keyline">
    <h2 className="mx-5 text-lg font-semibold leading-7 text-center lg:leading-tight lg:mx-auto lg:text-2xl">
      {title}
    </h2>

    <div className="max-w-5xl mx-auto overflow-hidden md:my-5">
      <FadingLogoStrip logos={logos} />
    </div>

    <Link
      to={cta.to}
      className="inline-flex items-center font-semibold transition text-primary-red hover:text-black focus:text-black"
    >
      <span className="mr-2.5">{cta.label}</span>
      <ArrowRight className="flex-shrink-0" />
    </Link>
  </section>
);

export default BrandsSection;
